import React, { Component, Fragment } from "react";
import ReactGA from 'react-ga';

import "./signupForm.scss";

class SignupForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      name: "",
      job_title: "",
      company_size: "50-100",
      error: "",
      success: "",
      loading: false
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  async handleSubmit(event) {
    event.preventDefault();

    this.setState({
      error: "",
      success: "",
      loading: true,
    });

    if (!this.state.agree) {
      ReactGA.event({
        category: "Create account",
        action: "User did not accept terms and conditions",
      });

      this.setState({
        error: "Please agree with the terms and conditions",
        loading: false,
      });
      return;
    }

    if (!this.validateEmail(this.state.email)) {
      ReactGA.event({
        category: "Create account",
        action: "User did not enter a valid email address",
      });

      this.setState({
        error: "Please enter a valid email address",
        loading: false,
      });
      return;
    }

    if (!this.state.name.length > 0) {
      ReactGA.event({
        category: "Create account",
        action: "User forgot to enter their name",
      });

      this.setState({
        error: "Please enter your name",
        loading: false,
      });
      return;
    }

    const createAccount = await fetch(
      `/register`,
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        method: "POST",
        body: JSON.stringify({
          email: this.state.email,
          name: this.state.name,
          job_title: this.state.job_title,
          company_size: this.state.company_size
        })
      }
    );

    const createAccountResponse = await createAccount.json();

    if(createAccountResponse.error) {
      ReactGA.event({
        category: "Create account",
        action: `User did nothing wrong, but something went wrong ${createAccountResponse.message || "Something went wrong"}`,
      });

      this.setState({
        error: createAccountResponse.message || "Something went wrong",
        loading: false,
      });
      return;
    }

    if(createAccountResponse.success) {
      ReactGA.event({
        category: "Create account",
        action: "User created account",
      });

      this.setState({
        success: createAccountResponse.message,
        error: "",
        email: "",
        name: "",
        job_title: "",
        company_size: "",
        loading: false,
      });

      return;
    }

    this.setState({
      error: "Something went wrong.. Please email me and I will help you as soon as possible."
    });
  }

  validateEmail(email) {
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  render() {
    return (
      <Fragment>
        <h1>Get a free API key</h1>
        <form onSubmit={this.handleSubmit} className="sign-up-form">
          <p className="error">{this.state.error}</p>
          <p className="success">{this.state.success}</p>

          <label>
            Email
            <input
              name="email"
              type="text"
              value={this.state.email}
              onChange={this.handleInputChange}
              placeholder="example@email.com"
            />
          </label>

          <label>
            Name
            <input
              name="name"
              type="text"
              placeholder="John doe"
              value={this.state.name}
              onChange={this.handleInputChange}
            />
          </label>

          <label>
            Job Title
            <input
              name="job_title"
              placeholder="Data analyst"
              type="text"
              value={this.state.job_title}
              onChange={this.handleInputChange}
            />
          </label>

          <label>
            Company size
            <div className="select-wrapper">
              <select
                value={this.state.company_size}
                onChange={this.handleInputChange}
                name="company_size"
              >
                <option value="1-10">1-10</option>
                <option value="10-50">10-50</option>
                <option value="50-100">50-100</option>
                <option value="100-500">100-500</option>
                <option value="500-1000">500-1000</option>
                <option value="1000+">1000+</option>
              </select>
            </div>
          </label>

          <label className="agree-checkbox">
            <input
              name="agree"
              type="checkbox"
              value={this.state.agree}
              onChange={this.handleInputChange}
              checked={this.state.agree}
            />
            <small>I understand that this is not investment advise, trading is at my own risk</small>
          </label>

          <input
            type="submit"
            value={this.state.loading ? "loading..." : "Get a free API key"}
            className="button button--primary"
            disabled={this.state.loading}
          />
        </form>
      </Fragment>
    );
  }
}

export default SignupForm;
