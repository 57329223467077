import users from "pages/users";
import React, { Component, Fragment } from "react";

import "./user-list.scss";

class UserList extends Component {
  renderUser(user) {
    return <li>
      {user.account.name} - {user.account.email} - {user.count} / {user.limit}
    </li>
  }

  render() {
    if (!this.props.users) {
      return (
        <main>
          <section className="container container-padding">Loading...</section>
        </main>
      );
    }

    if (this.props.account.success === false) {
      return (
        <main>
          <section className="container">
            <div className="UserList">
              <hr />
              <h3>You need to be logged in to view this page</h3>
              <hr />
            </div>
          </section>
        </main>
      );
    }

    return (
      <main>
        <section className="container container-padding">
          <div className="dashboard">
            <h1>Hi there!</h1>
            <h2>Current user count: {this.props.users.length}</h2>

            <div className="UserList__info">
              <p>
                You are logged in as {this.props.account.account.email}
              </p>
              <button onClick={() => this.props.logout()}>
                Logout
              </button>
            </div>

            <ul class="list">
              {this.props.users.sort((a,b) => a.count < b.count).map(user => this.renderUser(user))}
            </ul>

          </div>
        </section>
      </main>
    );
  }
}

export default UserList;
