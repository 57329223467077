import React from 'react';
import ReactGA from 'react-ga';
import "focus-visible";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { createBrowserHistory } from 'history';

import Dashboard from "./pages/dashboard";
import Documentation from "./pages/documentation";
import Faq from "./pages/faq";
import Home from "./pages/index";
import Login from "./pages/login";
import SignUp from "./pages/sign-up";
import Support from "./pages/support";
import Verify from "./pages/verify";
import PaymentPage from "./pages/payment";
import UsersPage from "./pages/users";
import NotFound from "./pages/not-found";
import PaymentSuccessful from "./pages/payment-successful";
import AccountService from 'services/accountService';

import './App.css';

const trackingId = "UA-154674701-1";
const accountService = new AccountService();
const history = createBrowserHistory();

ReactGA.initialize(trackingId);

ReactGA.set({
  userId: accountService.currentUserId(),
});

history.listen(location => {
  ReactGA.set({ page: location.pathname }); // Update the user's current page
  ReactGA.pageview(location.pathname); // Record a pageview for the given page
});

function App() {
  return (
    <Router history={history}>
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/documentation" component={Documentation} />
        <Route path="/dashboard" component={Dashboard} />
        <Route path="/faq" component={Faq} />
        <Route path="/login" component={Login} />
        <Route path="/sign-up" component={SignUp} />
        <Route path="/support" component={Support} />
        <Route path="/verify" component={Verify} />
        <Route path="/payment" component={PaymentPage} />
        <Route path="/users" component={UsersPage} />
        <Route path="/payment-successful" component={PaymentSuccessful} />
        <Route component={NotFound} />
      </Switch>
    </Router>
  );
}

export default App;
