import "./dashboard.scss";

import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";

class Dashboard extends Component {
  renderApiKey() {
    return (
      <Fragment>
        <h3>Your API key:</h3>
        <div className="dashboard__card dashboard__api-key">
          {this.props.account.key || "XXXXXX-XXXXXX-XXXXXX-XXXXXX-XXXXXX"}
        </div>
      </Fragment>
    );
  }

  renderLimits() {
    return (
      <Fragment>
        <div className="dashboard__stats">
          <h3>
            <label>{this.props.account.account.plan} plan</label> Usage stats (daily):{" "}
          </h3>
          <h3>Usage graph: </h3>
          <div className="dashboard__card dashboard__stats__today">
            <strong>
              {this.props.account.count}/{this.props.account.limit}
            </strong>{" "}
            API calls
          </div>
          <div className="dashboard__card dashboard__stats__graph">
            Coming soon
          </div>
        </div>
      </Fragment>
    );
  }

  render() {
    if (!this.props.account) {
      return (
        <main>
          <section className="container container-padding">Loading...</section>
        </main>
      );
    }

    if (this.props.account.success === false) {
      return (
        <main>
          <section className="container container-padding">
            <div className="dashboard">

              <h3>You need to be logged in to view this page</h3>
              <p>
                Follow this link to <Link to="/login">login</Link>
              </p>
              <hr />
            </div>
          </section>
        </main>
      );
    }

    return (
      <main>
        <section className="container container-padding">
          <div className="dashboard">
            <h1>Hi there!</h1>

            <div className="dashboard__info">
              <p>
                You are logged in as {this.props.account.account.email}
              </p>
              <button onClick={() => this.props.logout()}>
                Logout
              </button>
            </div>

            {this.renderApiKey()}
            {this.renderLimits()}
          </div>
        </section>
      </main>
    );
  }
}

export default Dashboard;
