import "../static/scss/global.scss";

import React, { Component, Fragment } from "react";

import Menu from "../components/Menu/Menu";
import SubNav from "../components/SubNav/SubNav";
import Footer from "../components/Footer/Footer";
import Documentation from "../components/Documentation/Documentation";

class Home extends Component {
  constructor() {
    super();

    this.state = {
      error: {
        status: false,
        error_message: ""
      },
      loading: false,
      search: ""
    };
  }

  componentDidMount() {}

  render() {
    return (
      <Fragment>
        <Menu />

        <SubNav />

        <Documentation />

        <Footer />
      </Fragment>
    );
  }
}

export default Home;
