import "../static/scss/global.scss";
import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";
import Menu from "../components/Menu/Menu";
import Footer from "../components/Footer/Footer";
import AccountService from "../services/accountService";

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      password: "",
      error: "",
      success: ""
    };

    this.accountService = new AccountService();

    this.router = this.props.router;

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  async handleSubmit(event) {
    event.preventDefault();

    this.setState({
      error: "",
      success: ""
    });

    try {
      if (!this.state.email.length > 0) {
        this.setState({
          error: "Please enter a email"
        });
        return;
      }

      if (!this.state.password.length > 0) {
        this.setState({
          error: "Please enter a password"
        });
        return;
      }

      const login = await this.accountService.login(
        this.state.email,
        this.state.password
      );

      if (login.status === 401) {
        this.setState({
          error: "Your username or password is incorrect"
        });

        return;
      }

      if (login.status !== 200) {
        this.setState({
          error: `Error with status code ${login.status} - ${login.statusText}`
        });

        return;
      }

      const loginResponse = await login.json();

      if (loginResponse.error) {
        this.setState({
          error: loginResponse.message || "Something went wrong"
        });
        return;
      }

      if (loginResponse.success) {
        this.props.history.push("/dashboard");

        this.setState({
          success: loginResponse.message,
          error: "",
          email: "",
          password: ""
        });

        return;
      }
    } catch (e) {
      this.setState({
        error: `Something went wrong.. ${e.message}`
      });
    }
  }

  render() {
    return (
      <Fragment>
        <Menu />

        <main>
          <section className="container container-padding light--text">
            <h1>Login</h1>
            <form onSubmit={this.handleSubmit} className="login-form">
              <p className="error">{this.state.error}</p>
              <p className="success">{this.state.success}</p>
              <label>
                Email
                <input
                  name="email"
                  type="email"
                  value={this.state.email}
                  onChange={this.handleInputChange}
                />
              </label>

              <label>
                password
                <input
                  name="password"
                  type="password"
                  value={this.state.repeat_password}
                  onChange={this.handleInputChange}
                />
              </label>

              <input
                type="submit"
                value="Login"
                className="button button--primary"
              />
            </form>
          </section>
        </main>
        <Footer />
      </Fragment>
    );
  }
}

export default withRouter(Login);
