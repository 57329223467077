import "../static/scss/global.scss";

import React, { Component } from "react";

import Menu from "../components/Menu/Menu";
import Footer from "../components/Footer/Footer";
import Payment from "../components/Payment/Payment";

class PaymentPage extends Component {
  constructor() {
    super();
    console.log(this.props.match.params.plan);
    this.state = {

    };
  }

  render() {
    return (
      <>
        <Menu />

        <main>
          <section className="container">
            <Payment />
          </section>
        </main>

        <Footer />
      </>
    );
  }
}

export default PaymentPage;
