import React, { Component } from "react";
import PreviewWindow from "./PreviewWindow";
import classNames from "classnames";

import "./previewCodeList.scss";

class PreviewCodeList extends Component {
  constructor(props) {
    super(props);

    const date = new Date().toISOString();

    this.state = {
      activeEndpoint: "Recommendations",
      endpoints: [
        {
          name: "Recommendations",
          description:
            "Get the recommendation of a coin. You're free to specify the coin & exchange of choice",
          link_to_documentation: "/documentation#tag/recommendation",
          method: "GET",
          path: "https://technical-analysis-api.com/api/v1/analysis/:coin",
          response: `{
  success: true
  exchange: "bitfinex"
  price_btc: 0.234
  date: ${date},
  recommendation: "buy"
  sentiment: 47.52
  strategies: { … }
  patterns: { … }
}`
        },
        {
          name: "Technical indicators",
          description:
            "When you're looking for a specific technical analysis of a cryptocurrency. You can get an analysis for SMA, ADX and many more. You can find all of the available strategies in the documentation",
          link_to_documentation: "/documentation#tag/strategy",
          method: "GET",
          path: "https://technical-analysis-api.com/api/v1/:strategy/:coin",
          response: `{
  strategy: "SMA",
  result: {
    ...
  }
}`
        },
        {
          name: "Sentiment",
          description:
            "The sentiment is based on the latest articles on Reddit. The sentiment output is generated based on the words used in the article.",
          link_to_documentation: "/documentation#tag/sentiment",
          method: "GET",
          path: "https://technical-analysis-api.com/api/v1/analyze/:coin",
          response: `{
  sentiment: 9.5,
  articles: [
    {
      date: 1552850590,
      title: "",
      content: "",
      upvotes: 71,
      downvotes: 0,
      image: "...",
      source: "...",
      sentiment: {
      score: 0,
      comparative: 0,
      tokens: [ ... ],
      words: [ ... ],
      positive: [ ... ],
      negative: [ ... ]
    }
  ]
}`
        }
      ]
    };
  }

  selectEndpoint(endpoint) {
    this.setState({
      activeEndpoint: endpoint
    });
  }

  render() {
    return (
      <div className="preview-code-list">
        <h1>Easy to use, straightforward API</h1>

        <div className="select-code-preview">
          {this.state.endpoints.map(endpoint => {
            return (
              <button
                className={classNames("button", "select-code-preview__item", {"select-code-preview__item--selected": endpoint.name === this.state.activeEndpoint})}
                onClick={() => this.selectEndpoint(endpoint.name)}
                key={endpoint.name}
              >
                {endpoint.name}
              </button>
            );
          })}
        </div>

        {this.state.endpoints.map(endpoint => {
          return (
            <div
              key={endpoint.name}
              className={classNames("code-preview", "container", {
                hide: endpoint.name !== this.state.activeEndpoint
              })}
            >
              <PreviewWindow endpoint={endpoint} />
            </div>
          );
        })}

        <a class="button button--primary button--center" href="/documentation">
          Check out the full documentation
        </a>
      </div>
    );
  }
}

export default PreviewCodeList;
