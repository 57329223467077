import "../static/scss/global.scss";

import React, { Component, Fragment } from "react";

import Menu from "../components/Menu/Menu";
import Footer from "../components/Footer/Footer";
import Mailto from "react-protected-mailto";

class FAQ extends Component {
  constructor() {
    super();

    this.state = {
      error: {
        status: false,
        error_message: ""
      },
      loading: false,
      search: ""
    };
  }

  render() {
    return (
      <Fragment>
        <Menu />
        <header className="header">

          <section className="content content--left container">
            <h1>
              Frequently asked questions
            </h1>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Temporibus repellat corrupti consequuntur. Accusantium, debitis vitae ipsum distinctio alias perspiciatis est.
            </p>
          </section>

          <section className="faq__item content--left container">
              <h2>
                How do I contact you
              </h2>
              <p>
              If you have any questions, feedback or something else. Please reach out to us at <Mailto
                email="info@timvandevathorst.nl"
                headers={{
                  subject: "Contact us"
                }}
              />
            </p>
          </section>

        </header>

        <Footer />
      </Fragment>
    );
  }
}

export default FAQ;
