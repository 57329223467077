import "../static/scss/global.scss";

import React, { Component, Fragment } from "react";
import Menu from "../components/Menu/Menu";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import InfoCards from "../components/InfoCards/InfoCards";
import SimpleContent from "../components/SimpleContent/SimpleContent";
import PreviewCodeList from "../components/CodePreview/PreviewCodeList";

class Home extends Component {
  constructor() {
    super();

    this.state = {
      error: {
        status: false,
        error_message: ""
      },
      loading: false,
      search: ""
    };
  }

  render() {
    return (
      <Fragment>
        <Menu />

        <Header />

        <main className="main">
          <section className="container">
            <InfoCards />
          </section>

          <section className="background--gray">
            <SimpleContent />
          </section>

          <section className="background--brand">
            <PreviewCodeList />
          </section>
        </main>

        <Footer />
      </Fragment>
    );
  }
}

export default Home;
