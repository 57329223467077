import React, { Component } from "react";
import { RedocStandalone } from "redoc";

import "./documentation.scss";

class Documentation extends Component {
  constructor(props) {
    super(props);
    this.specUrl = "/swagger.json";
  }

  render() {
    return (
      <main className="main container documentation">
        <RedocStandalone
          specUrl={this.specUrl}
          scrollYOffset={250}
          lazyRendering
          hideLoading
        />
      </main>
    );
  }
}

export default Documentation;
