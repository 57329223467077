import errorToJson from "../helpers/errorHelper";
import fetch from "isomorphic-unfetch";

const endpoint = "";

export default class AccountService {
  isAuthenticated() {
    return fetch(`${endpoint}/isAuthenticated`, {
      credentials: "include"
    })
      .then(response => {
        return response.json();
      })
      .then(json => {
        if (json.error === 404) {
          throw new Error("Not authenticated.");
        }
        return json;
      })
      .catch(error => {
        return errorToJson(error);
      });
  }

  /**
   * Login the user
   * @param {*} username
   * @param {*} password
   */
  login(username, password) {
    return fetch(`${endpoint}/login`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include",
      method: "POST",
      body: JSON.stringify({
        username,
        password
      })
    });
  }

  register() {
    // @TODO: move to service
  }

  async logout() {
    const response = await fetch(`${endpoint}/logout`, {
      credentials: "include",
      method: "get"
    });

    const json = await response.json();

    return json;
  }

  getAccount() {
    return fetch(`${endpoint}/account`, {
      credentials: "include"
    })
      .then(response => {
        return response.json();
      })
      .then(json => {
        if (json.error === 404) {
          throw new Error("Account not found");
        }
        return json;
      })
      .catch(error => {
        return errorToJson(error);
      });
  }

  async currentUserId() {
    const account = await this.getAccount();

    if(account) {
      return account.id;
    }

    return false;
  }

  async getApiInfo() {
    try {
      const request = await fetch(`${endpoint}/api-info`, {
        credentials: "include"
      });

      if(!request.status === 200) {
        throw new Error("Server offline?");
      }

      const json = request.json();

      if (json.error === 404) {
        throw new Error("Account not found");
      }

      return json;
    } catch (error) {
      return errorToJson(error);
    }
  }

  async getUsers() {
    try {
      const request = await fetch(`${endpoint}/users`, {
        credentials: "include"
      });

      if(!request.status === 200) {
        throw new Error("Server offline?");
      }

      const json = request.json();

      if (json.error === 404) {
        throw new Error("Account not found");
      }

      return json;
    } catch (error) {
      return errorToJson(error);
    }
  }

  async requireAuth() {
    const loggedIn = await this.getAccount();

    if (loggedIn && loggedIn.success === false) {
      return false;
    }

    return loggedIn;
  }

  reset(password, token) {
    return fetch(`${endpoint}/reset-password/${token}`, {
      body: JSON.stringify({
        password
      }),
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json"
      })
    })
      .then(response => {
        return response.json();
      })
      .then(json => {
        if (json.error === 404) {
          throw new Error("Could not connect. Please contact support");
        }
        return json;
      })
      .catch(error => {
        return errorToJson(error);
      });
  }

  change(passwordOld, passwordNew) {
    return fetch(`${endpoint}/change-password`, {
      body: JSON.stringify({
        passwordOld,
        passwordNew
      }),
      credentials: "include",
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json"
      })
    })
      .then(response => {
        return response.json();
      })
      .then(json => {
        if (json.error === 404) {
          throw new Error("Could not connect. Please contact support");
        }
        return json;
      })
      .catch(error => {
        return errorToJson(error);
      });
  }
}
