import "../static/scss/global.scss";
import { withRouter } from "react-router-dom";
import React, { Component } from "react";

import Menu from "components/Menu/Menu";
import Footer from "components/Footer/Footer";
import UserList from "components/UserList/UserList";
import AccountService from "services/accountService";

class UsersPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      account: null
    }

    this.accountService = new AccountService();
  }

  async componentDidMount() {
    const account = await this.accountService.getApiInfo();
    const userList = await this.accountService.getUsers();

    this.setState({
      account,
      users: userList
    });
  }

  async logout() {
    await this.accountService.logout();

    window.location.href = "/"
  }

  handleInputChange(event) {}

  render() {
    return (
      <>
        <Menu />
        {this.accountService.requireAuth() ? <UserList users={this.state.users} account={this.state.account} logout={() => this.logout()} /> : "<h1>Not authenticated</h1><p> Please login. </p>"}
        <Footer />
      </>
    );
  }
}

export default withRouter(UsersPage);
