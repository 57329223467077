import "../static/scss/global.scss";

import React, { Component, Fragment } from "react";
import Menu from "components/Menu/Menu";
import Footer from "components/Footer/Footer";
import Pricing from "components/Pricing/Pricing";

class Test extends Component {
  constructor() {
    super();

    this.state = {
      error: {
        status: false,
        error_message: ""
      },
      loading: false,
      search: ""
    };
  }

  render() {
    return (
      <Fragment>
        <Menu />

        <main>
          <section className="container">
            <Pricing />
          </section>
        </main>

        <Footer />
      </Fragment>
    );
  }
}

export default Test;
