import React, { Component } from "react";
import { Link } from "react-router-dom";

import "./footer.scss";

class Footer extends Component {
  render() {
    return (
      <footer className="footer container">
        <Link to="/" className="footer__logo">
          <h3>Technical analysis API</h3>
        </Link>
        <div className="footer__menu">
          <ul>
            <li>
              <Link to="/login">Login</Link>
            </li>
            <li>
              <Link to="/sign-up?register=true">Sign up</Link>
            </li>
            <li>
              <Link to="/dashboard">Dashboard</Link>
            </li>
          </ul>
        </div>

        <p className="footer__extra">
          Made with <span aria-label="blue heart ion" role="img">💙</span> by <a href="http://timvandevathorst.nl">Tim</a>
        </p>
      </footer>
    );
  }
}

export default Footer;
