import React, { Component } from "react";
import { Link } from "react-router-dom";

class Button extends Component {
  render() {
    return (
      <Link className="button button--primary" to={this.props.link || "#"}>
        {this.props.title}
      </Link>
    );
  }
}

export default Button;
