import "./header.scss";

import React, { Component } from "react";
import Button from "../Button/Button";

class Header extends Component {
  render() {
    return (
      <header className="header">
        <section className="container">
          <h1>Elevate your investing strategy with our expert-backed technical analysis API</h1>
          <Button title="Get a free API key" link="/sign-up" />
          <p className="recurring-user-text">Already have a account? <a href="/login">Sign in</a></p>
        </section>

        <div className="header__background">
          <img src={`${process.env.PUBLIC_URL}/images/lines.png`} alt="lines"/>
        </div>
      </header>
    );
  }
}

export default Header;
