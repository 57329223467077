import React, { Component } from "react";

import "./infoCard.scss";

class InfoCard extends Component {
  render() {
    return (
      <h2 className="info-card">
        <span className="number">{this.props.number}</span>
        {this.props.title}
      </h2>
    );
  }
}

export default InfoCard;
