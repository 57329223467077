import React, { Component } from "react";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import classNames from "classnames";
import Label from "../Label/Label";

import "./menu.scss";

class Menu extends Component {
  constructor(routerProvider) {
    super();

    this.route = routerProvider.location.pathname;

    this.state = {
      open: false,
      navigation_items: [
        { name: "Product", path: "/" },
        { name: "Docs", path: "/documentation" },
        { name: "Pricing", path: "/sign-up" },
        // { name: "FAQ", path: "faq" },
        { name: "Support", path: "/support" }
      ]
    };
  }

  toggleNavigation() {
    this.setState({
      open: !this.state.open
    });
  }

  render() {
    return (
      <nav className="container">
        <Link to="/" className="logo">
          Technical analysis API
          <Label title="BETA" />
        </Link>

        <div className="hamburger" onClick={e => this.toggleNavigation(e)}>
          ☰
        </div>

        <ul className={`navigation ${this.state.open ? "open" : ""}`}>
          {this.state.navigation_items.map(navigation_item => (
            <li
              key={navigation_item.path}
              className={classNames({
                active: this.route === navigation_item.path
              })}
            >
              <Link to={navigation_item.path}>
                {navigation_item.name}
              </Link>
            </li>
          ))}
        </ul>
      </nav>
    );
  }
}

export default withRouter(Menu);
