import "../static/scss/global.scss";
import { withRouter } from "react-router-dom";
import React, { Component } from "react";

import Menu from "components/Menu/Menu";
import Footer from "components/Footer/Footer";
import Dashboard from "components/Dashboard/Dashboard";
import AccountService from "services/accountService";

class DashboardPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      account: null
    }

    this.accountService = new AccountService();
  }

  async componentDidMount() {
    const account = await this.accountService.getApiInfo();

    this.setState({
      account
    });
  }

  async logout() {
    await this.accountService.logout();

    window.location.href = "/"
  }

  handleInputChange(event) {}

  render() {
    return (
      <>
        <Menu />
        {this.accountService.requireAuth() ? <Dashboard account={this.state.account} logout={() => this.logout()} /> : "<h1>Not authenticated</h1><p> Please login. </p>"}
        <Footer />
      </>
    );
  }
}

export default withRouter(DashboardPage);
