import React, { Component, Fragment } from "react";
import ReactGA from "react-ga";
import { withRouter, Link } from "react-router-dom";
import Modal from "react-modal";

import SignupForm from "components/SignupForm/SignupForm";
import AccountService from "services/accountService";

import "./pricing.scss";

class Pricing extends Component {
  constructor(props) {
    super(props);

    this.state = {
      account: null
    }

    this.accountService = new AccountService();

    Modal.setAppElement("#root");

    this.planDetails = {
      free: {
        id: "free-plan",
        name: "FREE",
        monthlyPrice: 0,
        monthlyPriceCurrency: "$",
        description: "For testing or hobby projects",
        disabled: false,
        button: "Sign up",
        perks: [
          {
            id: "support",
            description: "No support",
            check: false,
          },
          {
            id: "api-calls",
            description: "10 API calls / day",
            check: true,
          },
        ],
      },
      startup: {
        id: "startup-plan",
        name: "STARTUP",
        monthlyPrice: 4,
        monthlyPriceCurrency: "$",
        description: "Side projects and small start-ups",
        disabled: false,
        button: "Subscribe",
        stripeUrl: "https://buy.stripe.com/8wMg2i8xp7AybPW3cc",
        perks: [
          {
            id: "support",
            description: "Normal support",
            check: true,
          },
          {
            id: "api-calls",
            description: "1000 API calls / day",
            check: true,
          },
        ],
      },
      professional: {
        id: "professional-plan",
        name: "PROFESSIONAL",
        monthlyPrice: 25,
        monthlyPriceCurrency: "$",
        description: "The plan for professionals",
        disabled: false,
        button: "Subscribe",
        stripeUrl: "https://buy.stripe.com/3cs8zQ00T5sq1bi4gh",
        perks: [
          {
            id: "support",
            description: "Priority support",
            check: true,
          },
          {
            id: "api-calls",
            description: "10k API calls / day",
            check: true,
          },
        ],
      },
    };

    this.state = {
      modalIsOpen: Boolean(props.location.search.includes("register=true")),
      subscriptionModalIsOpen: false,
    };

    this.openSignupModal = this.openSignupModal.bind(this);
    this.openSubscriptionModal = this.openSubscriptionModal.bind(this);
    this.closeSubscriptionModal = this.closeSubscriptionModal.bind(this);
    this.closeSignupModal = this.closeSignupModal.bind(this);
  }

  async componentDidMount() {
    const account = await this.accountService.getApiInfo();

    this.setState({
      account
    });
  }

  openSignupModal() {
    ReactGA.event({
      category: "Open sign up modal",
      action: "User pressed the button to sign up in the pricing table",
    });

    this.setState({ modalIsOpen: true });
  }

  openSubscriptionModal(id) {
    ReactGA.event({
      category: "Open subscription modal",
      action: `User wants to subscribe to the ${id} plan`,
    });

    this.setState({ subscriptionModalIsOpen: true });
  }

  closeSignupModal() {
    this.setState({ modalIsOpen: false });
  }

  closeSubscriptionModal() {
    this.setState({ subscriptionModalIsOpen: false });
  }

  render() {
    return (
      <Fragment>
        <div className="select-plan">
          <h1>Upgrade your investing strategy <br />with our advanced API</h1>
          {/* <h3>
            Find the perfect technical analysis API plan for you
          </h3> */}
        </div>

        <div className="pricing-table">
          {Object.keys(this.planDetails).map((key) => {
            const plan = this.planDetails[key];

            return (
              <div className="pricing-box" id={plan.id} key={plan.id}>
                <span className="plan">{plan.name}</span>
                <span className="price">
                  {plan.monthlyPrice}
                  {plan.monthlyPriceCurrency} / Month
                </span>
                <p className="description">{plan.description}</p>
                <ul>
                  {plan.perks.map((perk) =>
                    perk.check ? (
                      <li key={perk.id}>
                        <span role="img" aria-label="Check">
                          ✅
                        </span>{" "}
                        {perk.description}
                      </li>
                    ) : (
                      <li key={perk.id}>
                        <span role="img" aria-label="X">
                          ❌
                        </span>{" "}
                        {perk.description}{" "}
                      </li>
                    )
                  )}
                </ul>
                <span className="pricing-table-divider" />

                {key === "free" ? (
                  <Link
                    to={`#${key}`}
                    className={`button button--primary ${
                      plan.disabled ? "button--disabled" : ""
                    }`}
                    onClick={
                      key === "free"
                        ? () => this.openSignupModal()
                        : () => this.openSubscriptionModal(plan.id)
                    }
                  >
                    {plan.button}
                  </Link>
                ) : (
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={`${plan.stripeUrl}${(this.state.account && this.state.account.account && this.state.account.account.email) ? "?prefilled_email=" + this.state.account.account.email : ""}`}
                    className={`button button--primary ${
                      plan.disabled ? "button--disabled" : ""
                    }`}
                  >
                    {plan.button}
                  </a>
                )}
              </div>
            );
          })}
        </div>

        <Modal
          isOpen={this.state.modalIsOpen}
          onAfterOpen={this.afterOpenModal}
          onRequestClose={this.closeSignupModal}
          contentLabel="Sign up modal"
          className="modal"
        >
          <button
            onClick={this.closeSignupModal}
            className="button button--close"
            aria-label="Close Sign-up Modal Box"
          >
            close &times;
          </button>
          <section className="container">
            <SignupForm />
          </section>
        </Modal>
      </Fragment>
    );
  }
}

export default withRouter(Pricing);
