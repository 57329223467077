import React, { Component } from 'react';
import "./label.scss";

class Label extends Component {
  render() {
    return (
      <label className="label label--primary">
        {this.props.title}
      </label>
    );
  }
}

export default Label;