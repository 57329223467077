import React, { Component } from "react";
import Button from "../Button/Button";

import "./simpleContent.scss";

class SimpleContent extends Component {
  render() {
    return (
      <div className="content container">
        <h1>Technical analysis is really complex</h1>
        <p>
          Are you new to technical analysis and feeling overwhelmed by all the
          different strategies? Our API aims to make it easy for everyone to use
          technical analysis by providing clear and concise descriptions for
          each API call. We even have a separate endpoint that gives you more
          information about each strategy. With our user-friendly approach, you
          can confidently incorporate technical analysis into your investing
          strategy. Maximize your success with our comprehensive and
          easy-to-understand technical analysis API.
        </p>
        <Button title="Get started" link="/sign-up" />
      </div>
    );
  }
}

export default SimpleContent;
