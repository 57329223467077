import React, { Component } from "react";
import { StripeProvider, Elements } from "react-stripe-elements";
import "./payment.scss";

import PaymentForm from "../PaymentForm/PaymentForm";

class Payment extends Component {
  render() {
    return (
      <div className="content payment">
        <h1>Payment form - this page is under construction.</h1>

        <StripeProvider apiKey="pk_test_12345">
          <Elements>
            <PaymentForm />
          </Elements>
        </StripeProvider>
      </div>
    );
  }
}

export default Payment;
