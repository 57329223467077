import React, { Component, Fragment } from "react";
import Prism from "prismjs";

import "static/scss/vendor/prism.scss";
import "./previewWindow.scss";

class PreviewWindow extends Component {
  constructor(props) {
    super(props);

    this.state = {
      prismHtml: ""
    }
  }

  componentDidMount() {
    const { endpoint } = this.props;

    var code = endpoint.response;

    if (!code) {
      return null;
    }

    const prismHtml = Prism.highlight(
      code,
      Prism.languages.javascript,
      "javascript"
    );

    this.setState({
      prismHtml
    });

    Prism.highlightAll();
  }

  render() {
    const { endpoint } = this.props;

    return (
      <Fragment>
        <div className="code-preview__content">
          <h2 className="code-preview__title">{endpoint.name}</h2>
          <p className="code-preview__description">{endpoint.description}</p>
          <a href={endpoint.link_to_documentation}>View documentation</a>
        </div>
        <div className="code-preview__header">
          <strong>{endpoint.method}</strong>
          {endpoint.path}
        </div>
        <pre>
          <code className="language-javascript" dangerouslySetInnerHTML={{
            __html: this.state.prismHtml
          }} />
        </pre>
      </Fragment>
    );
  }
}

export default PreviewWindow;
