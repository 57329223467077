import "../static/scss/global.scss";
import queryString from 'query-string';
import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";
import Menu from "../components/Menu/Menu";
import Footer from "../components/Footer/Footer";

class Verify extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: "",
      repeat_password: "",
      error: "",
      success: "",
      token: ""
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const values = queryString.parse(this.props.location.search);

    this.setState({
      token: values.token
    });
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  async handleSubmit(event) {
    event.preventDefault();

    this.setState({
      error: "",
      success: ""
    });

    if (!this.state.password.length > 0) {
      this.setState({
        error: "Please enter a password"
      });
      return;
    }

    if (!this.state.repeat_password.length > 0) {
      this.setState({
        error: "Please repeat your password"
      });
      return;
    }

    if (this.state.repeat_password !== this.state.password) {
      this.setState({
        error: "Password and repeat password do not match"
      });
      return;
    }

    const createAccount = await fetch(
      `/verify`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        method: "POST",
        body: JSON.stringify({
          password: this.state.password,
          token: this.state.token
        })
      }
    );

    const createAccountResponse = await createAccount.json();

    if (createAccountResponse.error) {
      this.setState({
        error: createAccountResponse.message || "Something went wrong"
      });
      return;
    }

    if (createAccountResponse.success) {
      this.setState({
        success: "Your account is created. You can nog login.",
        error: "",
        password: "",
        repeat_password: ""
      });

      this.props.history.push("/login");

      return;
    }

    this.setState({
      error:
        "Something went wrong.. Please send us an email and we will help you as soon as possible."
    });
  }

  render() {
    const requireToken = (
      <main className="main">
        <div className="container container-padding">
          <h1>
            Please click the link in your email. <br />{" "}
          </h1>
          <p className="dark--text">A verification token required to verify your account.</p>
        </div>
      </main>
    );
    const passwordForm = (
      <main className="main">
        <section className="container container-padding">
          <h1>Create account</h1>
          <form onSubmit={this.handleSubmit} className="verify-form">
            <p className="error">{this.state.error}</p>
            <p className="success">{this.state.success}</p>
            <label>
              Password
              <input
                name="password"
                type="password"
                value={this.state.password}
                onChange={this.handleInputChange}
                autoComplete="new-password"
              />
            </label>

            <label>
              Repeat password
              <input
                name="repeat_password"
                type="password"
                value={this.state.repeat_password}
                onChange={this.handleInputChange}
                autoComplete="new-password"
              />
            </label>

            <input
              type="submit"
              value="Create account"
              className="button button--primary"
            />
          </form>
        </section>
      </main>
    );

    return (
      <Fragment>
        <Menu />

        {this.state.token ? passwordForm : requireToken}

        <Footer />
      </Fragment>
    );
  }
}

export default withRouter(Verify);
