import "../static/scss/global.scss";
import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";

import Menu from "../components/Menu/Menu";
import Footer from "../components/Footer/Footer";
import Mailto from "react-protected-mailto";

class PaymentSuccessful extends Component {
  render() {
    return (
      <Fragment>
        <Menu />
        <section className="content container">
          <h1 className="heading heading--white">Yay. You're now ready to start building!</h1>
          <p>
            Thanks for registering! If you have any questions feel free to send
            a message to{" "}
            <Mailto
              email="info@timvandevathorst.nl"
              headers={{
                subject: "I have a question",
              }}
            />
          </p>

          <p>
            Go to <Link to="/dashboard">dashboard</Link>
          </p>
        </section>

        <Footer />
      </Fragment>
    );
  }
}

export default PaymentSuccessful;
