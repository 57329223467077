export default (error) => {
  console.log(error);
  if (error.message === "user not authenticated") {
    localStorage.removeItem("dashboardUser");
    window.location.href = "/login";
  }

  return {
    success: false,
    error: (error && error.message) || "something went wrong"
  };
}
