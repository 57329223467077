import "../static/scss/global.scss";

import React, { Component, Fragment } from "react";
import Menu from "../components/Menu/Menu";
import Footer from "../components/Footer/Footer";
import Mailto from "react-protected-mailto";

class Support extends Component {
  constructor() {
    super();

    this.state = {
      error: {
        status: false,
        error_message: ""
      },
      loading: false,
      search: ""
    };
  }

  render() {
    return (
      <Fragment>
        <Menu />
        <header className="header">

          <section className="content content--left container">
            <h1>
              <span role="img" aria-label="phone icon">☎️</span> Support
            </h1>
            <p>
              If you have any questions, feedback or something else. <br /> Please reach out to me at <Mailto
                email="info@timvandevathorst.nl"
                headers={{
                  subject: "Contact us"
                }}
              />
            </p>
          </section>

        </header>

        <Footer />
      </Fragment>
    );
  }
}

export default Support;
