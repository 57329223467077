import React, { Component } from 'react';
import InfoCard from './InfoCard/InfoCard';

import "./InfoCards.scss";

class InfoCards extends Component {
  render() {
    return (
      <div className="info-cards">
        <InfoCard number="6" title="Technical indicators"/>
        <InfoCard number="196+" title="Supported cryptocurrencies"/>
        <InfoCard number="2" title="Supported exchanges"/>
      </div>
    );
  }
}

export default InfoCards;