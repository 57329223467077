import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";

import AccountService from "services/accountService";

import "./subNav.scss";

class SubNav extends Component {
  constructor(routerProvider) {
    super();

    this.route = routerProvider.location.pathname;

    this.accountService = new AccountService();

    this.state = {
      open: false,
      navigation_items: []
    };

    this.renderNavigation();
  }

  async renderNavigation() {
    const isLoggedIn = await this.accountService.requireAuth();

    this.setState({
      navigation_items: [
        { name: "Login", path: "/login", enabled: !isLoggedIn },
        { name: "Sign up", path: "/sign-up?register=true", enabled: !isLoggedIn },
        { name: "Your dashboard", path: "/dashboard", enabled: isLoggedIn }
      ]
    })
  }

  toggleNavigation() {
    this.setState({
      open: !this.state.open
    });
  }

  render() {
    return (
      <div className="container subnav">
          {this.state.navigation_items.map(navigation_item => navigation_item.enabled && (
              <Link to={navigation_item.path} className="button button--subnav" key={navigation_item.name}>
                {navigation_item.name}
              </Link>
          ))}
      </div>
    );
  }
}

export default withRouter(SubNav);
